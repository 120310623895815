@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Ubuntu';
  src: url('../../public/font/Ubuntu-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Ubuntu';
  src: url('../../public/font/Ubuntu-BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Ubuntu';
  src: url('../../public/font/Ubuntu-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Ubuntu';
  src: url('../../public/font/Ubuntu-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Ubuntu';
  src: url('../../public/font/Ubuntu-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Ubuntu';
  src: url('../../public/font/Ubuntu-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Ubuntu';
  src: url('../../public/font/Ubuntu-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Ubuntu';
  src: url('../../public/font/Ubuntu-MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}

.chat-enter {
  opacity: 1;
  transform: translateX(0px) translateY(0px) scale(1);
  transition: all 0.2s ease;
}

.chat-exit {
  opacity: 0;
  transform: translateX(175px) translateY(350px) scale(0);
  transition: all 0.4s ease;
}

/* Ant design datetime range picker adjusted for mobile */
@media all and (max-width: 640px) {
  .ant-picker-panels {
    display: flex;
    flex-direction: column;
  }
}

@media all and (max-width: 450px) {
  .ant-picker-datetime-panel {
    display: flex;
    flex-direction: column;
  }
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hide-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.fade-in {
  animation: fade-in 1s ease;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  30% {
    opacity: 1;
  }
}

.bubble-slide-left {
  animation: bubble-slide-left 1s ease;
}
@keyframes bubble-slide-left {
  0% {
    opacity: 0;
    transform: translateX(100px);
  }
  60% {
    opacity: 1;
    transform: translateX(0px);
  }
}
.bubble-slide-right {
  animation: bubble-slide-right 1s ease;
}
@keyframes bubble-slide-right {
  0% {
    opacity: 0;
    transform: translateX(-100px);
  }
  60% {
    opacity: 1;
    transform: translateX(0px);
  }
}

.slide-from-right {
  animation: bubble-slide-right 500ms ease;
}
@keyframes bubble-slide-right {
  0% {
    opacity: 0;
    transform: translateX(-25px);
  }
  60% {
    opacity: 1;
    transform: translateX(0px);
  }
}

.slide-from-top {
  animation: slide-from-top 1s ease;
}
@keyframes slide-from-top {
  0% {
    opacity: 0;
    transform: translateY(-25px);
  }
  30% {
    opacity: 1;
    transform: translateY(0px);
  }
}

.slide-from-bottom {
  animation: slide-from-bottom 1s ease;
}
@keyframes slide-from-bottom {
  0% {
    opacity: 0;
    transform: translateY(15px);
  }
  30% {
    opacity: 1;
    transform: translateY(0px);
  }
}

.MuiInputBase-multiline textarea {
  scrollbar-width: none;
}

.noBorder {
  border: none !important;
}

.noBorder .MuiOutlinedInput-notchedOutline {
  border: none;
}

.noBorder .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
  border: none;
}

.noBorder .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border: none;
}

/* ANT DESIGN */
.ant-tabs-nav-list {
  flex-direction: row !important;
}

.ant-tour {
  max-width: 100vw;
}

.ant-tour-target-placeholder {
  @apply border !important;
  @apply border-primary !important;
  @apply dark:border-dark-primary !important;
}

.ant-btn-primary {
  @apply bg-primary !important;
  @apply text-on-primary !important;
  @apply dark:bg-dark-primary !important;
  @apply dark:text-dark-on-primary !important;
}

.ant-btn-primary:hover {
  @apply bg-secondary/80 !important;
  @apply text-on-secondary !important;
  @apply dark:bg-dark-secondary/80 !important;
  @apply dark:text-dark-on-secondary !important;
}

.ant-btn-primary:disabled {
  @apply opacity-50 !important;
}

.ant-popconfirm-message-text {
  width: max-content;
}

.ant-popconfirm-message-icon,
.ant-picker-separator {
  margin-bottom: 6px;
}

.ant-segmented-item-label {
  margin-bottom: 2px;
}

.ant-popconfirm-message-icon .anticon-exclamation-circle {
  display: flex;
  margin-top: 5px;
}

.ant-tabs-tab-btn {
  margin: 0 2em;
}

.ant-popconfirm-title,
.ant-popconfirm-description {
  @apply text-on-surface !important;
  @apply dark:text-dark-on-surface !important;
}

#scroller * {
  overflow-anchor: none;
}
#anchor {
  overflow-anchor: auto;
  height: 1px;
}

html,
body {
  max-width: 100vw;
  min-height: 100dvh;
  overflow-x: hidden;
}

.main {
  text-align: center;
  min-height: 100dvh;
}

a {
  color: inherit;
  text-decoration: none;
}

.ant-segmented-item-label {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.ant-collapse-header {
  padding: 0px;
}

.ant-collapse.ant-collapse-icon-position-end
  > .ant-collapse-item
  > .ant-collapse-header
  .ant-collapse-expand-icon {
  padding-inline-start: 2px;
}

.ant-collapse-small > .ant-collapse-item > .ant-collapse-header {
  padding: 0px 0px 8px;
}

.ant-collapse-borderless
  > .ant-collapse-item
  > .ant-collapse-content
  > .ant-collapse-content-box {
  padding: 0px;
}

.markdown-answer h1,
.markdown-answer h2,
.markdown-answer h3,
.markdown-answer h4,
.markdown-answer h5,
.markdown-answer h6 {
  margin: 0.5em 0;
}

.markdown-answer p {
  margin: 0.5em 0;
}

.markdown-answer h1 {
  font-size: 2em;
}

.markdown-answer h2 {
  font-size: 1.5em;
}

.markdown-answer h3 {
  font-size: 1.3em;
}

.markdown-answer a {
  font-weight: bold;
  text-decoration: none;
}

.markdown-answer a:hover {
  text-decoration: underline;
}

.markdown-answer img {
  max-width: 100%;
}

.markdown-answer ul,
.markdown-answer ol {
  padding-left: 2em;
}

.markdown-answer li {
  list-style-type: disc;
}

.markdown-answer code {
  @apply text-on-background;
  @apply bg-background;
  @apply dark:text-dark-on-background;
  @apply dark:bg-dark-background;
  @apply text-wrap;
  @apply rounded;
  padding: 0.1em 0.2em;
}

.markdown-answer pre {
  @apply text-on-background;
  @apply bg-background;
  @apply dark:text-dark-on-background;
  @apply dark:bg-dark-background;
  @apply rounded;
  padding: 0.4em 0.6em;
  overflow: auto;
  margin: 1em 0;
}

.ant-modal-wrap {
  @apply backdrop-blur-sm !important;
}

.ant-popover-inner {
  @apply border !important;
  @apply border-solid !important;
  @apply border-on-background/20 !important;
  @apply dark:border-dark-on-background/20 !important;
}

.ant-message-notice-content {
  @apply bg-background !important;
  @apply text-on-background !important;
  @apply dark:bg-dark-background !important;
  @apply dark:text-dark-on-background !important;
  @apply border !important;
  @apply border-solid !important;
  @apply border-on-background/20 !important;
  @apply dark:border-dark-on-background/20 !important;
}

.ant-tabs-nav .ant-upload-list {
  overflow-y: auto;
  max-height: 120px;
}

.ant-upload-list-picture-card {
  overflow: hidden !important;
}

.ant-upload {
  padding: 0 !important;
}

.ant-upload-drag {
  border: none !important;
}

.ant-upload-list-item {
  @apply text-primary !important;
  @apply dark:text-dark-primary !important;
}

.ant-upload-list-item-name {
  flex: none !important;
}

.ant-upload-icon span {
  @apply text-primary !important;
  @apply dark:text-dark-primary !important;
}

.ant-upload-icon span {
  @apply text-primary !important;
  @apply dark:text-dark-primary !important;
}

.ant-upload-list-item-actions span {
  @apply text-primary !important;
  @apply dark:text-dark-primary !important;
}

.ant-checkbox-checked .ant-checkbox-inner:after {
  @apply border-on-primary !important;
  @apply dark:border-dark-on-primary !important;
}

.ant-tabs-nav {
  margin: 0 !important;
}

@media screen and (max-width: 500px) {
  .ant-tabs-nav-list {
    flex-direction: column;
  }
}

.ant-card-body {
  height: 100%;
}

.ant-progress-inner {
  @apply bg-on-surface/10 !important;
  @apply dark:bg-dark-on-surface/10 !important;
}

.graph-tooltip,
.scene-tooltip {
  @apply bg-surface !important;
  @apply text-on-surface !important;
  @apply dark:bg-dark-surface !important;
  @apply dark:text-dark-on-surface !important;
  @apply rounded-lg !important;
  @apply z-50 !important;
}

.pulsate {
  box-shadow: 0 0 0;
  @apply shadow-background !important;
  @apply dark:shadow-dark-background !important;
  animation: anim-pulsate 2s normal;
  transform: scale(1);
}

.pulsate-infinite {
  animation: anim-pulsate 2.5s infinite;
  transform: scale(1);
}

@keyframes anim-pulsate {
  0% {
    transform: scale(1);
  }

  60% {
    transform: scale(1.06);
  }

  100% {
    transform: scale(1);
  }
}

.pulsate-infinite2 {
  animation: anim-pulsate2 2.5s infinite;
  transform: scale(1);
}

@keyframes anim-pulsate2 {
  0% {
    transform: scale(1.06);
  }

  60% {
    transform: scale(1);
  }

  100% {
    transform: scale(1.06);
  }
}

/* SmartGrants specific styles for autofill */
/* Light theme */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #ffffff inset !important; /* Adjust the box-shadow color as needed */
  -webkit-text-fill-color: #1e1d1d !important; /* Light theme text color */
}

/* Dark theme */
.dark input:-webkit-autofill,
.dark input:-webkit-autofill:hover,
.dark input:-webkit-autofill:focus,
.dark input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #312f2f inset !important; /* Adjust the box-shadow color for dark theme if needed */
  -webkit-text-fill-color: #ffffff !important; /* Dark theme text color */
}
